import React from 'react';
import Container from '@mui/material/Container';

import Layout from '../../components/Layout';
import Seo from '../../components/seo';

export const Head = () => <Seo title="Aggregate usage tracking" />;

export default function Contact() {
  return (
    <Layout>
      <Container maxWidth="md" component="main" className="numbered">
        <h1>Do you use any subprocessors?</h1>
        <p>
          Plainice uses the following subprocessors, all of which are GDPR and
          CCPA compliant and are located in the USA:
        </p>
        <ol>
          <li>GCP - Web hosting, servers, databases</li>
          <li>Stripe - Payment collection and handling</li>
          <li>Google Analytics - Aggregate usage tracking</li>
          <li>Mailgun — Service emails</li>
        </ol>
      </Container>
    </Layout>
  );
}
